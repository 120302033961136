import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'amfe-flexible'
import 'vant/lib/index.css'
import AMap from 'vue-amap';
Vue.use(AMap);
Vue.config.productionTip = false
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad, {
  error: '', //加载失败的图
  loading: '' //加载中的默认图
})
// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: '7941f234156fa4cd4777be4970ea9424',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4'
});
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
