import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "@/views/Index.vue"
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
    redirect: '/index/content',
    children: [
      {
        path: '/index/content',
        name: 'content',
        component: () => import('../views/son/content.vue')
      },
      {
        path: '/index/industrial',
        name: 'index_industrial',
        component: () => import('../views/son/hxgy.vue'),
      },
      {
        path: '/index/brand',
        name: 'index_brand',
        component: () => import('../views/son/ppjs.vue'),
      },
      {
        path: '/index/news',
        name: 'index_news',
        component: () => import('../views/son/xwzx.vue'),
      },
      {
        path: '/index/contact',
        name: 'index_contact',
        component: () => import('../views/son/lxwm.vue'),
      },
      {
        path: '/index/newsList',
        name: 'index_newsList',
        component: () => import('../views/son/newsList.vue'),
      },
      {
        path: '/index/carved',
        name: 'index_carved',
        component: () => import('../views/son/cpzs/carved.vue'),
      },
      {
        path: '/index/classic',
        name: 'index_classic',
        component: () => import('../views/son/cpzs/classic.vue'),
      },
      {
        path: '/index/man',
        name: 'index_man',
        component: () => import('../views/son/cpzs/man.vue'),
      },
      {
        path: '/index/plastic',
        name: 'index_plastic',
        component: () => import('../views/son/cpzs/plastic.vue'),
      },
      {
        path: '/index/royal',
        name: 'index_royal',
        component: () => import('../views/son/cpzs/royal.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
