<template>
  <div style="height: 100%">
    <div class="top">
      <img class="topImg" src="../assets/img/logo.png" alt="" />
      <img
        @click="nav"
        class="topImg2"
        src="../assets/img/顶部nav.png"
        alt=""
      />
    </div>
    <div class="a"></div>
    <div class="nav" v-if="demo">
      <div class="navtop"></div>
      <div class="directory">
        <div class="language">
          <div @click="navfont(1)" :class="act == 1 ? 'active' : ''">
            中文 / CHINESE
          </div>
          <div @click="navfont(2)" :class="act == 2 ? 'active' : ''">
            英文 / ENGLISH
          </div>
        </div>
      </div>
      <div class="h40">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('content')"
          src="../assets/img/WAP点击右上角分类_02.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('content')"
          src="../assets/img/eWAP点击右上角分类_02.jpg"
          alt=""
        />
      </div>
      <div class="h40">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('industrial')"
          src="../assets/img/WAP点击右上角分类_03.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('industrial')"
          src="../assets/img/eWAP点击右上角分类_03.jpg"
          alt=""
        />
      </div>
      <div class="h36">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('classic')"
          src="../assets/img/WAP点击右上角分类_04.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('classic')"
          src="../assets/img/eWAP点击右上角分类_04.jpg"
          alt=""
        />
      </div>
      <div class="h30">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('classic')"
          src="../assets/img/WAP点击右上角分类_05.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('classic')"
          src="../assets/img/eWAP点击右上角分类_05.jpg"
          alt=""
        />
      </div>
      <div class="h30">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('royal')"
          src="../assets/img/WAP点击右上角分类_06.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('royal')"
          src="../assets/img/eWAP点击右上角分类_06.jpg"
          alt=""
        />
      </div>
      <div class="h30">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('plastic')"
          src="../assets/img/WAP点击右上角分类_07.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('plastic')"
          src="../assets/img/eWAP点击右上角分类_07.jpg"
          alt=""
        />
      </div>
      <div class="h30">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('man')"
          src="../assets/img/WAP点击右上角分类_08.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('man')"
          src="../assets/img/eWAP点击右上角分类_08.jpg"
          alt=""
        />
      </div>
      <div class="h30">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('carved')"
          src="../assets/img/WAP点击右上角分类_09.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('carved')"
          src="../assets/img/eWAP点击右上角分类_09.jpg"
          alt=""
        />
      </div>
      <div class="h40">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('brand')"
          src="../assets/img/WAP点击右上角分类_10.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('brand')"
          src="../assets/img/eWAP点击右上角分类_10.jpg"
          alt=""
        />
      </div>
      <div class="h40">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('news')"
          src="../assets/img/WAP点击右上角分类_11.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('news')"
          src="../assets/img/eWAP点击右上角分类_11.jpg"
          alt=""
        />
      </div>
      <div class="h40">
        <img
          v-if="act == 1"
          class="one"
          @click="navpath('contact')"
          src="../assets/img/WAP点击右上角分类_12.jpg"
          alt=""
        />
        <img
          v-if="act == 2"
          class="one"
          @click="navpath('contact')"
          src="../assets/img/eWAP点击右上角分类_12.jpg"
          alt=""
        />
      </div>
    </div>
    <div v-if="!demo">
      <router-view />
    </div>
    <div class="footer" v-if="!demo">
      <div class="foot1">
        <img class="one" src="../assets/img/WAP首页_02.jpg" alt="" />
      </div>
      <div class="foot2">
        <div class="font1">
          <span style="color: #fff">企业介绍&nbsp;&nbsp;丨&nbsp;&nbsp;</span>
          <span
            ><span @click="navpath('brand')">品牌介绍</span>&nbsp;&nbsp; /&nbsp;
            <span  @click="navpath('brand')">团队介绍</span>&nbsp; /&nbsp;
            <span @click="navpath('contact')">联系我们</span>
          </span>
        </div>
        <div class="font">
          <span style="color: #fff">产品介绍&nbsp;&nbsp;丨&nbsp;&nbsp;</span>
          <span
            ><span @click="navpath('classic')">产品展示</span>&nbsp;&nbsp;
            /&nbsp;<span @click="navpath('industrial')">核心工艺</span>&nbsp;
            /&nbsp; <span @click="navpath('news')">新闻中心</span>
          </span>
        </div>
      </div>
      <div class="foot3">
        <img
          class="one"
          @click="ba"
          src="../assets/img/WAP首页_04.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    } else {
      window.location.href = "http://www.ladypraise.com/";
    }
    // window.location.href = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? "http://localhost:8081/" :  "http://localhost:8080/";
    // window.location.href = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? "http://mobile.ladypraise.com/" :  "http://www.ladypraise.com/";
  },
  data() {
    return {
      demo: false,
      act: 1,
    };
  },
  methods: {
    ba() {
      window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
    },
    nav() {
      this.demo = !this.demo;
    },
    navfont(num) {
      this.act = num;
      if (num == 2) {
        window.location.href = "http://mobile.ladypraise.com/en/";
      }
    },
    navpath(path) {
      this.$router.push(path);
      this.demo = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.a {
  height: 50px;
}
.active {
  color: #980b12 !important;
  border-bottom: 3px solid #980b12 !important;
}
.nav {
  .navtop {
    width: 375px;
    height: 15px;
    background-color: #ededed;
  }
  .directory {
    padding: 30px 50px 0;
    .language {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      color: #73757a;
      border-bottom: 1px solid #d7d7d7;
      div {
        padding-bottom: 10px;
        border-bottom: 3px solid #73757a;
      }
    }
  }
  .h40 {
    width: 100%;
    height: 41px;
  }
  .h36 {
    height: 36px;
    width: 100%;
  }
  .h30 {
    height: 30px;
    width: 100%;
  }
}
.top {
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 335px;
  padding: 16px 20px;
  .topImg {
    width: 144px;
    height: 19px;
  }
  .topImg2 {
    width: 18px;
    height: 18px;
  }
}
.footer {
  .foot1 {
    width: 375px;
    height: 120px;
    margin-top: 1px;
  }
  .foot2 {
    font-size: 12px;
    color: #adadad;
    background: #333333;
    padding: 0 40px;
    .font1 {
      padding-top: 18px;
      border-top: 1px solid #5a5a5a;
    }
    .font {
      margin-top: 20px;
      padding-bottom: 18px;
      border-bottom: 1px solid #5a5a5a;
    }
  }
  .foot3 {
    width: 375px;
    height: 60px;
  }
}
</style>